import React from 'react';

const Backend = () => {
  return (
    <div className="skills__content">
        <h3 className="skills__title">Backend</h3>
        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name">C#</h3>
                        <span className="skills__level">Intermedio Avanzado</span>
                    </div>
                </div>
            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">VB.Net</h3>
                    <span className="skills__level">Avanzado</span>
                </div>
            </div>
            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">JAVA</h3>
                    <span className="skills__level">Basico</span>
                </div>
            </div>
        </div>

        <div className="skills__group">
            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">MYSQL</h3>
                    <span className="skills__level">Intermedio</span>
                </div>
            </div>
            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">SQL</h3>
                    <span className="skills__level">Avanzado</span>
                </div>
            </div>
        </div>
    </div>
    </div>
  );
};

export default Backend