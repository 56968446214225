import React from 'react'

const info = () => {
  return (
    <div className="about__info grid">
        <div className="about__box">
            <i class="bx bx-award about__icon"></i>
            <h3 className="about__title">Experienca</h3>
            <span className="about__subtitle">6 años de experiencia</span>
        </div>

        <div className="about__box">
            <i class="bx bx-briefcase-alt about__icon"></i>
            <h3 className="about__title">Completos</h3>
            <span className="about__subtitle">5  proyectos</span>
        </div>

        <div className="about__box">
            <i class="bx bx-support about__icon"></i>
            <h3 className="about__title">Suporte</h3>
            <span className="about__subtitle">en linea 24/7</span>
        </div>
    </div>
  )
}

export default info