import React from 'react'

const Social = () => {
  return (
    <div className="home__social">
        <a href="https://www.linkedin.com/in/jesus-favila-4b4617118/" className="home__social-icon" target='_blank' rel="noreferrer">
            <i class="uil uil-linkedin"></i>
        </a>
 
        <a href="https://www.facebook.com/jesus.guereca.73" className="home__social-icon" target='_blank' rel="noreferrer">
            <i class="uil uil-facebook"></i>
        </a>

    </div>
  )
}

export default Social